@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Product Sans";
  src: url("../public/assets/fonts/Product\ Sans\ Regular.ttf");
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  line-height: 1;
}

body {
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: bottom;
  background-image: url(/public/assets/bg.png);
  @apply font-productSans text-darkBlue
}

:root {
  --Phoneinput-color--focus: #03b2cb;
  --PhoneInputInternationalIconPhone-opacity: 0.8;
  --PhoneInputInternationalIconGlobe-opacity: 0.65;
  --PhoneInputCountrySelect-marginRight: 0.35em;
  --PhoneInputCountrySelectArrow-width: 0.3em;
  --PhoneInputCountrySelectArrow-marginLeft: var(
    --PhoneInputCountrySelect-marginRight
  );
  --PhoneInputCountrySelectArrow-borderWidth: 1px;
  --PhoneInputCountrySelectArrow-opacity: 0.45;
  --PhoneInputCountrySelectArrow-color: inherit;
  --PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountrySelectArrow-transform: rotate(45deg);
  --PhoneInputCountryFlag-aspectRatio: 1.5;
  --PhoneInputCountryFlag-height: 1em;
  --PhoneInputCountryFlag-borderWidth: 1px;
  --PhoneInputCountryFlag-borderColor: rgba(0, 0, 0, 0.5);
  --PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountryFlag-backgroundColor--loading: rgba(0, 0, 0, 0.1);
}

.PhoneInputCountrySelect {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  border: 0;
  opacity: 0;
  cursor: pointer;
}
.PhoneInputCountry {
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  margin-right: 0.35em;
  margin-right: 24px;
}
.PhoneInput {
  display: flex;
  align-items: center;
  gap: 10px;
}

.PhoneInputCountryIconImg {
  display: block;
  width: 100%;
  height: 100%;
}
.PhoneInputCountryIcon {
  height: 16px;
  width: 24px;
}

.PhoneInputCountrySelectArrow {
  display: block;
  content: "";
  width: 0.3em;
  width: var(--PhoneInputCountrySelectArrow-width);
  height: 0.3em;
  height: var(--PhoneInputCountrySelectArrow-width);
  margin-left: 0.35em;
  margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
  border-style: solid;
  border-color: inherit;
  border-color: var(--PhoneInputCountrySelectArrow-color);
  border-top-width: 0;
  border-bottom-width: 1px;
  border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
  border-left-width: 0;
  border-right-width: 1px;
  border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
  -webkit-transform: rotate(45deg);
  -webkit-transform: var(--PhoneInputCountrySelectArrow-transform);
  transform: rotate(45deg);
  transform: var(--PhoneInputCountrySelectArrow-transform);
  opacity: 0.45;
  opacity: var(--PhoneInputCountrySelectArrow-opacity);
}

.bottom-arrow:after {
  content:'';
  position: absolute;
  top: -9px;
  right: 8%;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-bottom: 10px solid #16325C;
  border-left: 14px solid transparent;
  border-right: 14px solid transparent;
}

/* CODE INPUT */


.verificationInput_container {
  max-width: 500px;
}

.verificationInput_character {
  font-weight: 500;
  border: none;
  border-radius: 8px;
  height: 55px;
}


.custom-styles {
  --ReactInputVerificationCode-itemWidth: 50px;
  --ReactInputVerificationCode-itemHeight: 60px;
  --ReactInputVerificationCode-itemSpacing: 0;
}

.custom-styles .ReactInputVerificationCode__container {
  gap: 10px;
}

.custom-styles .ReactInputVerificationCode__item {
  font-weight: 500;
  @apply bg-white text-darkBlue flex-1
}

.custom-styles .ReactInputVerificationCode__item::placeholder {color: red}

.custom-styles .ReactInputVerificationCode__item,
.custom-styles .ReactInputVerificationCode__item.is-active {
  box-shadow: #007A9B !important;
}



/* Boton de medio circulo con flecha */
@media (min-width: 768px) {
  .next-step-circle-button{
    -moz-border-radius: 100px 0 0 100px;
    -webkit-border-radius: 100px 0 0 100px;
    @apply w-[40px] h-[80px] xl:w-[70px] xl:h-[140px]
  }
}

@media (max-width: 767px) {
  .parent-modal1:nth-child(2n - 2) {
    background-color: #AAC2FF14;
  }
  
  .parent-modal2:nth-child(2n - 1) {
    background-color: #AAC2FF14;
  }
}

select {
  background-color: transparent;
}

/* botones pantalla principal, tipo de estudio/test */
.test-card {
  @apply flex items-center sm:justify-center rounded-lg py-4 md:py-5 px-4 w-full shadow-2 min-w-max gap-2 md:gap-4
}

.test-card:hover, .test-card:focus {
  @apply bg-darkBlue text-white
}

.custom-scrollbar::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}
.custom-scrollbar::-webkit-scrollbar-track:hover {
  background-color: #f4f4f4;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 8px;
}
.custom-scrollbar::-webkit-scrollbar-button {
  display: none;
}

/* CALENDARIO  */
.react-calendar {
  line-height: 1.125em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  border-bottom: 1px solid #bebebe48;
}

/* flechas de navegacion */
.react-calendar__navigation button {
  /* min-width: 150px; */
  font-size: 20px;
}

/* dias de la semana */
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 1em 0;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}

/* fines de semana */
.react-calendar__month-view__days__day--weekend {
  /* color: #d10000; */
}

/* dias que no son del mes */
.react-calendar__month-view__days__day--neighboringMonth {
  color: #858585ad;
}

/* vista de año */
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

/* celdas/dias */
.react-calendar__tile {
  max-width: 100%;
  padding: 10px 0;
  background: none;
  text-align: center;
  line-height: 16px;
}

/* disabled days */
.react-calendar__tile:disabled {
  /* color: #BEBEBE; */
  opacity: 0.2
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}

/* dia seleccionado */
.react-calendar__tile--active {
  background: #16325C;
  color: white;
  border-radius: 50px;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #16325C;
  border-radius: 50px;
}

.react-calendar__tile--hasActive {
  background: #16325C;
  color: white;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #16325C;
  color: white;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.ambiente {
  color: white;
  background-color: darkorange;
  font-size: 16px;
  border: solid 1px;
  padding-left: 10px;
  padding-right: 10px;
}
